import {useState, useRef, useEffect} from "react";
import {
    Box, Button, IconButton, Modal, Typography, List, Grid,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CrossIcon from "@mui/icons-material/CloseSharp";
import Header from "../Header/Header";
import MicNoneIcon from "@mui/icons-material/MicNone";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import WaveForm from "./WaveForm";
import TranscriptionHistory from "./TranscriptionHistory";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import data from "../../services/Data";
import {toast} from "react-toastify";
import axios from "axios";
import {Audio} from "react-loader-spinner";

export default function MedicalNotes() {
    const navigate = useNavigate();
    const location = useLocation();

    const [userData, setUserData] = useState({});
    const [transactions, setTransactions] = useState();

    const [recording, setRecording] = useState(false);
    const [paused, setPaused] = useState(false);
    const mediaRecorderRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const [mainPage, setMainPage] = useState(true);
    const [recordedChunks, setRecordedChunks] = useState("");
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const fetchUserData = async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            setUserData(user);
            setMainPage(true);
            const response = await axios.get(
                `https://api.medscribeai.solutions/Audio/Recording/${user.userID}`
            );
            if (response) {
                setHistoryData(response.data.records)
            }
        }
    };

    const handleAddNewMedicalNote = () => {
        if (location.pathname !== "/medical-notes") {
            navigate("/medical-notes");
        }
    };

    useEffect(() => {
        fetchUserData();
        handleAddNewMedicalNote()
    }, []);

    const user = JSON.parse(localStorage.getItem("user"));
    console.log({user})
    if (!user?.email || user?.id === 0) {
        navigate(`/login`);
        location.href = "/login";
    }
    // To get the current path

    //To get the current record id from the route (param)
    const {recordId} = useParams();

    const [isSideBarOpen, setIsSideBarOpen] = useState(window.innerWidth > 1365)

    console.log({historyData})
    const medicalData = historyData.find(
        (item) => item.audioID === parseInt(recordId)
    );
    const medicalBackground = medicalData ? medicalData.translation : "";

    useEffect(() => {
        setMainPage(location.pathname === "/medical-notes");
    }, [location.pathname]);

    const handleStartRecording = () => {
        navigator.mediaDevices
            .getUserMedia({audio: true})
            .then((stream) => {
                mediaStreamRef.current = stream;
                const recorder = new MediaRecorder(stream);
                recorder.ondataavailable = async (e) => {
                    if (e.data.size > 0) {
                        // Convert blob data to base64
                        const reader = new FileReader();
                        reader.onloadend = async () => {
                            const base64Data = reader.result.split(",")[1];
                            try {
                                setLoading(true);
                                const response = await axios.post(
                                    `https://flask-yuhi.onrender.com/upload-audio`,
                                    {
                                        mail: user.email,
                                        audio: base64Data, // Send base64 encoded data
                                    }
                                );

                                // Generate random 5-digit ID
                                const generateRandomId = () => {
                                    return Math.floor(10000 + Math.random() * 90000);
                                };

                                // Create transcription object
                                const newTranscription = {
                                    id: generateRandomId(),
                                    date: new Date(),
                                    translation: response.data.message,
                                    transcript: response.data.transcript,
                                    base64Data
                                };

                                setRecordedChunks(newTranscription);
                                setOpenModal(true);
                                // toast.success("Audio saved!", { autoClose: 2000 });
                                setLoading(false);
                            } catch (error) {
                                toast.error("Something went wrong", {autoClose: 2000});
                                setLoading(false);
                            }
                        };
                        reader.readAsDataURL(e.data);
                    }
                };
                recorder.onstop = () => {
                    stream.getTracks().forEach((track) => track.stop());
                };
                mediaRecorderRef.current = recorder;
                recorder.start();
                setRecording(true);
            })
            .catch((error) => {
                console.error("Error accessing microphone:", error);
            });
    };

    const handleStopRecording = async () => {    
        if (mediaRecorderRef.current && recording) {
            mediaRecorderRef.current.stop();
            setRecording(false);
            setPaused(false);
        }
        console.log({recordedChunks});
    };

    const handlePauseRecording = () => {
        if (mediaRecorderRef.current && recording && !paused) {
            mediaRecorderRef.current.pause();
            setPaused(true);
        }
    };

    const handleResumeRecording = () => {
        if (mediaRecorderRef.current && recording && paused) {
            mediaRecorderRef.current.resume();
            setPaused(false);
        }
    };

    useEffect(() => {
        window.openNav = function () {
            document.getElementById("navbarID").style.width = "250px";
            document.getElementById("navbarID").style.display = "block";
        };

        window.closeNav = function () {
            document.getElementById("navbarID").style.width = "0";
            document.getElementById("navbarID").style.display = "none";
        };
    }, []);
    const handleLike = async () => {
        try {
            setLoading(true);
            console.log({recordedChunks});
            const response = await axios.post(
                `https://api.medscribeai.solutions/Audio/Recording/Save`,
                {
                    audioID: recordedChunks?.id,
                    userID: user.userID,
                    translation: recordedChunks.translation,
                    audiobytes: recordedChunks?.audiobytes,
                    transcript: recordedChunks?.transcript,
                    audioRecordingPath: recordedChunks?.base64Data,
                    likes: true,
                    dislike: false,
                    statusID: 1,
                    createdDate: recordedChunks?.date,
                    createdBy: user.email,
                }
            );


            console.log({response})

            setRecordedChunks("");
            fetchUserData().then(() => {
                toast.success("Record saved!", {autoClose: 2000});
                setLoading(false);
            })
        } catch (error) {
            toast.error("Something went wrong", {autoClose: 2000});
            setLoading(false);
        }
        setOpenModal(false);
    };
    const handleDislike = async () => {
        console.log({recordedChunks});
        try {
            setLoading(true);
            const response = await axios.post(
                `https://api.medscribeai.solutions/Audio/Recording/Save`,
                {
                    audioID: recordedChunks?.id,
                    userID: user.userID,
                    translation: recordedChunks.translation,
                    audiobytes: recordedChunks?.audiobytes,
                    transcript: recordedChunks?.transcript,
                    audioRecordingPath: recordedChunks?.base64Data,
                    likes: false,
                    dislike: true,
                    statusID: 1,
                    createdDate: recordedChunks?.date,
                    createdBy: user?.email,
                }
            );

            setRecordedChunks("");
            fetchUserData().then(() => {
                toast.success("Record saved!", {autoClose: 2000});
                setLoading(false);
            })
        } catch (error) {
            toast.error("Something went wrong", {autoClose: 2000});
            setLoading(false);
        }
        setOpenModal(false);
    };

    if (!user) {
        return (
            <>
                <p>loading...</p>
            </>
        );
    }

    const handelTranscriptionClick = (recordId) => {
        navigate(`/medical-notes/${recordId}`);
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true, // Use 12-hour format (AM/PM)
        };
        return date.toLocaleDateString(undefined, options);
    };
    return (

        <>
            {/*header*/}
            <Header setIsSideBarOpen={setIsSideBarOpen} isSideBarOpen={isSideBarOpen}/>

            <div className="container-fluid">
                <div className="row" style={{height: '87vh'}}>
                    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 navbar" id="navbarID"
                         style={{background: "#292f38", display: isSideBarOpen ? 'block' : 'none', width:"100%"}}>
                        <div className="align-items-center align-items-sm-start px-1 pt-2 text-white">
                            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                                id="menu">
                                <li className="nav-item pb-2 w-100 d-lg-none d-md-none d-xl-none ">
                                    <a className="nav-link align-middle px-0 d-flex justify-content-end align-items-center mr-3"
                                       onClick={() => setIsSideBarOpen(false)}>
                                        <CrossIcon className="mb-10" style={{
                                            color: "#01b2df",
                                            fontSize: "22px",
                                            marginLeft: "10px"
                                        }}/>
                                    </a>
                                </li>
                                <li className="nav-item pb-2">
                                    <a className="nav-link align-middle px-0 d-flex justify-content-center align-items-center"
                                       onClick={handleAddNewMedicalNote}>
                                        <span className="ms-1 d-sm-inline"
                                              style={{color: "white", fontSize: "20px", fontWeight: "600"}}>New Medical Note</span>
                                        <AddCircleIcon className="mb-10" style={{
                                            color: "#01b2df",
                                            fontSize: "22px",
                                            marginLeft: "10px"
                                        }}/>
                                    </a>
                                </li>
                                <li className="nav-item pb-0">
                                    <a href="#" className="nav-link align-middle px-0 pb-0">
                   <span className="ms-1 d-sm-inline"
                         style={{color: "white", fontSize: "20px", fontWeight: "600"}}>Transcription History
                    <hr style={{color: "white", border: "1px solid white"}}/>
                   </span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link align-middle p-0">
                   <span className="ms-1 d-sm-inline"
                         style={{color: "white", fontSize: "23px", fontWeight: "600"}}>
                   <List style={{maxHeight: '65vh', overflowY: 'auto'}}>
            {historyData &&
                historyData.map((record) => (
                    <ListItem
                        key={record.id}
                        disablePadding
                        style={{marginBottom: "10px"}}
                    >
                        <ListItemButton
                            className="pl-0"
                            onClick={() => {handelTranscriptionClick(record.audioID);if(window.innerWidth<800) setIsSideBarOpen(false)}}
                        >
                            <ListItemText
                                className={"list-item-texts"}
                                disableTypography={true}
                                primary={`Transcription - ${formatDate(record.createdDate)}`}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
          </List>
                   </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9 col-xl-10" style={{height: "80vh", textAlign: "center"}}>
                        {loading && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent background overlay
                                    zIndex: 9999, // Ensure it's on top of other content
                                }}
                            >
                                <Audio
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#077e99"
                                    ariaLabel="loading"
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        )}

                        <Modal
                            open={openModal}
                            // onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "80%",
                                    maxHeight: "80vh", // Set max height to 80% of viewport height
                                    overflowY: "auto", // Enable vertical scrolling if content exceeds max height
                                    bgcolor: "background.paper",
                                    border: "2px solid #000",
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <Typography id="modal-modal-description" sx={{mt: 2}}>
            <pre style={{width: "100%", whiteSpace: "pre-wrap"}}>
              {recordedChunks?.translation}
            </pre>
                                </Typography>
                                <Box>
                                    <Button onClick={handleLike}>Like</Button>
                                    <Button onClick={handleDislike}>Dislike</Button>
                                </Box>
                            </Box>
                        </Modal>

                        <div className={"page-layout"}>
                            {/*left section*/}
                            <TranscriptionHistory historyData={historyData}/>

                            {/* Main page content */}
                            {mainPage && (
                                <div className={"main-page-content"}>
                                    {recording ? (
                                        <WaveForm mediaStream={mediaStreamRef.current}/>
                                    ) : (
                                        <div className={"main-page-text"} style={{marginTop: "5rem"}}>
                                            <h2 className={"white-color"}>
                                                Click on the button to start recording.
                                            </h2>
                                        </div>
                                    )}
                                    {!recording && (
                                        <div className={"start-record-container"}>
                                            {/* Show the mic icon for start recording */}
                                            <IconButton
                                                size="small"
                                                aria-label="record"
                                                onClick={handleStartRecording}
                                            >
                                                <MicNoneIcon className={"recording-icons"}/>
                                            </IconButton>
                                            <span className={"white-color"}>Start recording</span>
                                        </div>
                                    )}

                                    {recording && (
                                        <div
                                            className={"pause-and-stop-container"}
                                            style={{width: "fit-content"}}
                                        >
                                            <IconButton
                                                size="small"
                                                aria-label="stop"
                                                onClick={handleStopRecording}
                                            >
                                                <StopCircleIcon className={"recording-icons"}/>
                                            </IconButton>

                                            <IconButton
                                                size="small"
                                                aria-label={paused ? "resume" : "pause"}
                                                onClick={
                                                    paused ? handleResumeRecording : handlePauseRecording
                                                }
                                            >
                                                <PauseCircleIcon
                                                    className={"recording-icons"}
                                                    sx={{color: paused ? "orange" : "#077e99"}}
                                                />
                                            </IconButton>
                                            <span className={"white-color"}>
                  {paused ? "Paused" : "Recording..."}
                </span>
                                        </div>
                                    )}
                                </div>
                            )}

                            {!mainPage && (
                                <div className={"details-content"}>
                                    <div className={"medical-background"}>
                                        <h1 className={"details-heading"}>Medical Note</h1>
                                        <p className={"details-p"}>
                <pre style={{width: "200%", whiteSpace: "pre-wrap", color: "white"}}>
                  {medicalBackground}
                </pre>
                                        </p>
                                    </div>
                                    <br/>
                                    <div className={"tests"}>
                                        {/* <h1 className={"details-heading"}>Tests</h1> */}
                                        {/* <p className={"details-p"}>{tests}</p> */}
                                    </div>
                                    <br/>
                                    <div className={"more"}>
                                        {/* <h1 className={"details-heading"}>More</h1> */}
                                        {/* <p className={"details-p"}>{more}</p> */}
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
