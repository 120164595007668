import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import data from "../../services/Data";
import { useLocation, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect, useState } from "react";

export default function TranscriptionHistory({ historyData }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handelTranscriptionClick = (recordId) => {
    navigate(`/medical-notes/${recordId}`);
  };

  const handleAddNewMedicalNote = () => {
    if (location.pathname !== "/medical-notes") {
      navigate("/medical-notes");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 12-hour format (AM/PM)
    };
    return date.toLocaleDateString(undefined, options);
  };

  // return (
  //   <div className="container-fluid">
  //     <div className="row">
  //       <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0" style={{background:"#292f38"}}>
  //         <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
  //           <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
  //             <li className="nav-item">
  //               <a className="nav-link align-middle px-0" onClick={handleAddNewMedicalNote}>
  //                 <span className="ms-1 d-none d-sm-inline"  style={{color: "white", fontSize: "23px", fontWeight: "600"}} >New Medical Note</span>
  //                 <AddCircleIcon className="mb-10" style={{ color: "#01b2df", fontSize: "28px",margin: "10px" }} /> 
  //               </a>
  //               <hr style={{ padding: "25px" }} />
  //             </li>
  //             <li className="nav-item">
  //               <a href="#" className="nav-link align-middle px-0">
  //                  <span className="ms-1 d-none d-sm-inline"  style={{color: "white", fontSize: "23px", fontWeight: "600"}} >Transcription History
  //                   <hr style={{ color:"white", border: "1px solid white" }} />
  //                  </span>
  //               </a>
  //             </li>
  //             <li className="nav-item">
  //               <a href="#" className="nav-link align-middle px-0">
  //                  <span className="ms-1 d-none d-sm-inline"  style={{color: "white", fontSize: "23px", fontWeight: "600"}} >
  //                  <List>
  //           {historyData &&
  //             historyData.map((record) => (
  //               <ListItem
  //                 key={record.id}
  //                 disablePadding
  //                 style={{ marginBottom: "30px" }}
  //               >
  //                 <ListItemButton
  //                   onClick={() => handelTranscriptionClick(record.id)}
  //                 >
  //                   <ListItemText
  //                     className={"list-item-texts"}
  //                     disableTypography={true}
  //                     primary={`Transcription - ${formatDate(record.date)}`}
  //                   />
  //                 </ListItemButton>
  //               </ListItem>
  //             ))}
  //         </List>
  //                  </span>
  //               </a>
  //             </li>
  //           </ul>        
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  //   // <Grid sx={{ width: "350px", minHeight: "calc(100vh - 80px)" }}>
  //   //   <Box sx={{ backgroundColor: "#292F38", height: "100%" }}>
  //   //     <div
  //   //       className={"add-button-container"}
  //   //       onClick={handleAddNewMedicalNote}
  //   //     >
  //   //       <span
  //   //         style={{
  //   //           color: "white",
  //   //           fontWeight: "600",
  //   //           fontSize: "23px",
  //   //           marginBottom: "30px",
  //   //           marginTop: "30px",
  //   //         }}
  //   //       >
  //   //         New Medical Note
  //   //       </span>
  //   //       <AddCircleIcon style={{ color: "#01b2df", fontSize: "28px" }} />
  //   //     </div>

  //   //     <div className={"left-section-header-text"}>
  //   //       <h2 style={{ color: "white", fontWeight: "500", fontSize: "18px" }}>
  //   //         Transcription History
  //   //       </h2>
  //   //       <hr style={{ width: "90%" }} />

  //   //       <List>
  //   //         {/*display all records under the Transcription history*/}
  //   //         {/* {recordedChunks && <div>{recordedChunks}</div>} */}
  //   //         {historyData &&
  //   //           historyData.map((record) => (
  //   //             <ListItem
  //   //               key={record.id}
  //   //               disablePadding
  //   //               style={{ marginBottom: "30px" }}
  //   //             >
  //   //               <ListItemButton
  //   //                 onClick={() => handelTranscriptionClick(record.id)}
  //   //               >
  //   //                 <ListItemText
  //   //                   className={"list-item-texts"}
  //   //                   disableTypography={true}
  //   //                   primary={`Transcription - ${formatDate(record.date)}`}
  //   //                 />
  //   //               </ListItemButton>
  //   //             </ListItem>
  //   //           ))}
  //   //       </List>
  //   //     </div>
  //   //   </Box>
  //   // </Grid>
  // );
}
