import {useState, useRef, useEffect} from "react";
import {AppBar, Toolbar, Typography, IconButton, Box} from "@mui/material";
import logoImage from "../../images/png-8.png";
import {useNavigate} from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
// import ColorPalette from "../../services/ColorPalette";
export default function Header({setIsSideBarOpen, isSideBarOpen}) {
    const navigate = useNavigate();
    const handleLogout = () => {
        console.log("Logged out");
        localStorage.removeItem("loggedIn");
        navigate("/login");
    };
    return (
            <nav style={{display: (isSideBarOpen && window.innerWidth < 450) ? 'none' : 'block',boxShadow: "2px 2px #292f38",height: '115px'}} className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light box-shadow">
                <div className="container-fluid d-flex justify-content-between align-items-center" style={{
                    height: '15vh',
                    display: 'flex !important',
                    justifyContent: 'space-between !important',
                    alignItems: 'center !important'
                }}>
                    <span className="navbar-toggler-icon" onClick={() => setIsSideBarOpen(!isSideBarOpen)}></span>
                    <img src={logoImage}
                         alt="Logo"
                         style={{height: "70px", width: "100px", zoom: 2}}/>
                    <div className="d-sm-inline-flex flex-sm-row-reverse">
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <IconButton edge="end" aria-label="logout" onClick={handleLogout}>
                                <LogoutIcon className={"logout"}/>
                            </IconButton>
                        </Box>
                    </div>
                </div>
            </nav>
        // <AppBar position="static" sx={{ background: "#353A42", height: "80px" }}>
        //   <Toolbar>
        //     <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        //       <img
        //         src={logoImage}
        //         alt="Logo"
        //         style={{ height: "140px", width: "200px", marginLeft: "-50px" }}
        //       />
        //     </Typography>
        //     <Box sx={{ display: "flex", alignItems: "center" }}>
        //       <IconButton edge="end" aria-label="logout" onClick={handleLogout}>
        //         <LogoutIcon className={"logout"} />
        //       </IconButton>
        //     </Box>
        //   </Toolbar>
        // </AppBar>
    );
}
