import "./App.css";
import MedicalNotes from "./components/MedicalNotes/MedicalNotes";
import Login from "./components/Login/Login";
import {MemoryRouter, Navigate, Route, Routes} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <MemoryRouter>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<MedicalNotes />} />
        {/* Use PrivateRoute as a wrapper around the protected routes */}
        <Route path="/medical-notes" element={<MedicalNotes />} />
        <Route path="/medical-notes/:recordId" element={<MedicalNotes />} />
      </Routes>
    </MemoryRouter>
  );
}

export default App;
