import React, { useState } from "react";
import styles from "./Login.module.css"; // Importing the CSS module
import logoImage from "../../images/png-8.png";
import { Button, FormControl, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  localStorage.clear();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (name === "" || email === "" || password === "") {
        toast.error("Please fill all fields", { autoClose: 2000 });
        return;
      }
      const response = await axios.get(
        `https://api.medscribeai.solutions/Login/UserLogin/${name}/${email}/${password}`
      );
      console.log({response})
      if (response.data.description === "Login Success.") {
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("user", JSON.stringify(response?.data?.login));
        toast.success("Login Successful!", { autoClose: 2000 });
        navigate("/medical-notes");
      }else{
        toast.error("Wrong details entered!", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Something went wrong", { autoClose: 2000 });
      console.error("Error:", error.message);
    }
  };

  return (
<div className="container" style={{height: "100vh"}}>
  <div className="row justify-content-center h-100 align-items-center">
    <div className="col-xl-10 col-lg-12 col-md-9">
      <div className="card o-hidden border-0 shadow-lg my-5">
        <div className="card-body p-20">
          <div className="row">
            <div className="col-lg-6 d-none d-lg-block" style={{background: "#526470"}}>
            <div className="text-center form-group">
                <img src={logoImage}
                     alt="Logo"
                     className="img-fluid"
                 />
                <svg className={styles.container__svg2}
                     version="1.2"
                     baseProfile="tiny-ps"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 348 18"
                     width="348"
                     height="18"></svg>
              </div>
            </div>
            <div className="col-md-6" style={{background: "white"}}>
              <div className="text-center">
                <div className="h4 text-gray-900 mb-4 d-block d-sm-none">                
                <img src={logoImage}
                     alt="Logo"
                     className="img-fluid"
                     style={{height:"140px", width:"200px"}}
                 />
                 </div>
              </div>
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input type="text"
                         className="form-control"
                         id="username"
                         value={name}
                         onChange={(e) => setName(e.target.value)}
                  placeholder="Enter username"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email"
                         className="form-control"
                         id="email"
                         value={email}
                         onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                        autoComplete="email"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input type="password"
                         className="form-control"
                         id="password"
                         value={password}
                         onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  />
                </div>
                <button type="submit"
                        className="btn btn-primary btn-block"
                        style={{ backgroundColor: "#526470" }}>
                  Login / Register
                </button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default Login;
