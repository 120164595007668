import React, { useRef, useEffect } from "react";

const WaveForm = ({ mediaStream, width = 800 }) => {
    const canvasRef = useRef(null);
    const analyserRef = useRef(null);

    useEffect(() => {
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioCtx.createAnalyser();
        analyser.fftSize = 2048;

        const source = audioCtx.createMediaStreamSource(mediaStream);
        source.connect(analyser);
        analyserRef.current = analyser;

        return () => {
            // Clean up on unmount
            source.disconnect();
            analyser.disconnect();
            audioCtx.close();
        };
    }, [mediaStream]);

    useEffect(() => {
        if (!analyserRef.current || !canvasRef.current) return;

        const canvasCtx = canvasRef.current.getContext("2d");
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);

        const draw = () => {
            if (!canvasRef.current) return;
            requestAnimationFrame(draw);
            const canvasCtx = canvasRef.current.getContext("2d");
            analyserRef.current.getByteFrequencyData(dataArray);

            const WIDTH = canvasRef.current.width;
            const HEIGHT = canvasRef.current.height;

            canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

            const barWidth = (WIDTH / dataArray.length) * 2.5;
            const barSpacing = 1;

            dataArray.forEach((data, index) => {
                const barHeight = (data / 255) * HEIGHT * 0.8;
                const x = index * (barWidth + barSpacing);
                const y = HEIGHT - barHeight;

                // Colors based on volume
                const volumeFactor = data / 255;
                const r = Math.round(volumeFactor * (29 - 7) + 7);
                const g = Math.round(volumeFactor * (178 - 31) + 31);
                const b = Math.round(volumeFactor * (153 - 61) + 61);

                canvasCtx.fillStyle = `rgb(${r}, ${g}, ${b})`;
                canvasCtx.fillRect(x, y, barWidth, barHeight);
            });
        };

        draw();
    }, [width]);

    return <canvas ref={canvasRef} width={400} height={300} />;
};

export default WaveForm;
